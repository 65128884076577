import React from "react";
import "@babylonjs/loaders";
import * as GUI from "@babylonjs/gui";
import "@babylonjs/inspector";
import { SCENE } from "./Scene";
import SinkConfig from "./SinkConfig";
import * as BABYLON from "@babylonjs/core";
import { getSettings } from "../graphql";
import { useQuery } from "@apollo/client";
import { calcArea, map } from "../helpers";

import {
  Vector3,
  SceneLoader,
  ArcRotateCamera,
  Camera,
  DefaultRenderingPipeline,
  DefaultLoadingScreen,
} from "@babylonjs/core";

//import SceneComponent from "./SceneComponent"; // uses above component in same directory
import SceneComponent from "babylonjs-hook"; // if you install 'babylonjs-hook' NPM.
//var SinkConfig = Object.create(conf);
//Glossyfactor
/* if(localStorage.getItem("SinkConfig") != null){
  SinkConfig = Object.create(JSON.parse(localStorage.getItem("SinkConfig")));
  console.log("SinkConfig loaded from localstorage",JSON.parse(localStorage.getItem("SinkConfig")));
  console.log(SinkConfig.tableWidth)
  localStorage.removeItem("SinkConfig");
  
  
} */
const glossfactor = 0.1;

//import { useState } from "react";

const Canvas = (props) => {
  React.useEffect(() => {
    calcPrice();
  }, [SinkConfig.materialid]);

  function calcPrice() {
    //console.log("Berechne Preis")
    //Berechnung Waschtischpreis
    let basispreis = 1450; //Basispreis für 1000mm Tischlänge
    let tischaufschlag = 0.75; //Aufschlag pro 1mm > 1000
    let tischabschlag = 0.5; //Abschlag pro 1mm < 1000
    let auflagenpreis = 75; //Preis pro Auflage
    let ablageauflagepreis = 100;
    let Haendlerrabbatt = 0.65; //Preisreduktion für Händler
    let mwst = 0.19; //Mehrwertsteuersatz
    let basispreisablage = 480;
    let ablageaufschlag = 0.4;
    let ablageabschlag = 0.3;
    let nettopreis = 0;
    let produktionspreis = 0;
    let aufschlagPoliert = 1.3;
    let rueckverguetung = 0.95;
    if (process.env.REACT_APP_BASEPRICE_DISCOUNT === "1") {
      basispreis = basispreis * rueckverguetung;
    }
    //Auf- bzw. Abschlag für Tischgröße berechnen
    if (SinkConfig.tableWidth > 1000) {
      basispreis = basispreis + (SinkConfig.tableWidth - 1000) * tischaufschlag;
    } else if (SinkConfig.tableWidth < 1000) {
      basispreis = basispreis - (1000 - SinkConfig.tableWidth) * tischabschlag;
    }

   
    //Anzahl der Auflagen berechnen
    if (SinkConfig.tableWidth < 1300) {
      auflagenpreis *= 2;
    } else {
      auflagenpreis *= 3;
    }
    //Aufpreis Doppelbecken berechnen
    if (SinkConfig.singleSink === 0) {
      basispreis += 280;
    }

    //Berechnung Ablagepreis
    if (SinkConfig.tableWidth > 1000) {
      basispreisablage =
        basispreisablage + (SinkConfig.tableWidth - 1000) * ablageaufschlag;
    } else if (SinkConfig.tableWidth < 1000) {
      basispreisablage =
        basispreisablage - (1000 - SinkConfig.tableWidth) * ablageabschlag;
    }
    //Berechnung Auflagepreis für Ablage
    if (SinkConfig.tableWidth < 1300) {
      ablageauflagepreis *= 2;
    } else {
      ablageauflagepreis *= 3;
    }
    basispreisablage += ablageauflagepreis;
    //Gesamtpreis berechnen
    let gesamtpreis = 0;

    if (SinkConfig.withUnderTable) {
      if (process.env.REACT_APP_INCLUDE_DISCOUNT === "1") {
        gesamtpreis =
          (basispreis + auflagenpreis + basispreisablage) / Haendlerrabbatt;
      } else {
        gesamtpreis = basispreis + auflagenpreis + basispreisablage;
      }
    } else {
      if (process.env.REACT_APP_INCLUDE_DISCOUNT === "1") {
        gesamtpreis = (basispreis + auflagenpreis) / Haendlerrabbatt;
      } else {
        gesamtpreis = basispreis + auflagenpreis;
      }
    }
    if (SinkConfig.materialglossy) {
      gesamtpreis *= aufschlagPoliert;
    }
    //Materialaufpreis
    let materialprice =
      SinkConfig.materialprice *
      calcArea(
        SinkConfig.tableWidth,
        SinkConfig.tableDepth,
        SinkConfig.tableHeight,
        SinkConfig.sinkWidth,
        SinkConfig.beckenTiefe,
        SinkConfig.beckenHoehe,
        SinkConfig.singleSink
      );
    //Preisaufschlag Händler
    SinkConfig.endpreisHaendler = gesamtpreis.toFixed(2);
    gesamtpreis *= data.einstellungen.data.attributes.Preismultiplikator;
    //Materialpreis
    gesamtpreis += materialprice;
    SinkConfig.endpreisMaterial = materialprice.toFixed(2);

     //Aufpreis für größere Beckenhöhe
     if (SinkConfig.tableHeight > 150) {
      gesamtpreis = gesamtpreis  * 1.25;
    }
    //Mehrwertsteuer
    gesamtpreis = gesamtpreis + gesamtpreis * mwst;
    SinkConfig.endpreisfloat = gesamtpreis;

    //setEndpreis(gesamtpreis.toFixed(2))
    props.setEndprice(SinkConfig.endpreisfloat.toFixed(2));
  }

  //var layer = new BABYLON.Layer('','', Scene, true);
  const { loading, error, data } = useQuery(getSettings);
  if (data) {
    var posLineCenter = new GUI.Line();
    var posLineLeft = new GUI.Line();
    var posLineRight = new GUI.Line();
    var posLineCentertext = new GUI.TextBlock();
    var sinkWidthLineCenter = new GUI.Line();
    var sinkWidthLineLeft = new GUI.Line();
    var sinkWidthLineRight = new GUI.Line();
    var tischWidthLineCenter = new GUI.Line();
    var tischWidthLineLeft = new GUI.Line();
    var tischWidthLineRight = new GUI.Line();
    var newSink = null;
    DefaultLoadingScreen.prototype.displayLoadingUI = function () {
      if (document.getElementById("customLoadingScreenDiv")) {
        // Do not add a loading screen if there is already one
        //document.getElementById("customLoadingScreenDiv").style.display = "initial";
        return;
      }
    };

    //Scene Loading Progress
    function sceneLoadProgress(event, scene) {
      let percentage = (event.loaded / event.total) * 100;

      // replace the default loading screen

      // show the loading screen
    }

    //Setup the scene parameters and variables
    function setupScene(scene) {
      //var Becken = scene.getMeshByID("Becken");

      var engine = scene.getEngine();
      // show the loading screen
      //engine.hideLoadingUI();

      // lights
      var spotlightLeft = scene.getLightByID("Area");
      var spotlightRight = scene.getLightByID("Area.001");
      var spotlightMiddle = scene.getLightByID("Area.002");
      var sun = scene.getLightByID("Sun");

      var faucetSinkRight = scene.getMeshByID("f_sink").clone("right");
      var faucetWallRight = scene.getMeshByID("f_wall").clone("right");
      //var shadowGenerator = spotlightRight.getShadowGenerator();
      const shadowGeneratorRight = new BABYLON.ShadowGenerator(
        1024,
        spotlightRight
      );
      const shadowGeneratorLeft = new BABYLON.ShadowGenerator(
        1024,
        spotlightLeft
      );

      shadowGeneratorRight.addShadowCaster(scene.getMeshByID("Becken"));
      shadowGeneratorRight.addShadowCaster(scene.getMeshByID("f_sink"));
      shadowGeneratorRight.addShadowCaster(scene.getMeshByID("right.f_sink"));
      shadowGeneratorRight.addShadowCaster(scene.getMeshByID("f_wall"));
      shadowGeneratorRight.addShadowCaster(scene.getMeshByID("right.f_wall"));
      shadowGeneratorRight.addShadowCaster(scene.getMeshByID("Soap"));
      shadowGeneratorLeft.addShadowCaster(scene.getMeshByID("Soap"));
      shadowGeneratorLeft.addShadowCaster(scene.getMeshByID("Becken"));
      shadowGeneratorLeft.addShadowCaster(scene.getMeshByID("f_sink"));
      shadowGeneratorLeft.addShadowCaster(scene.getMeshByID("right.f_sink"));
      shadowGeneratorLeft.addShadowCaster(scene.getMeshByID("f_wall"));
      shadowGeneratorLeft.addShadowCaster(scene.getMeshByID("right.f_wall"));

      shadowGeneratorLeft.usePercentageCloserFiltering = true;
      shadowGeneratorRight.usePercentageCloserFiltering = true;

      spotlightLeft.direction = new BABYLON.Vector3(0.078, -0.273, 0.4);
      spotlightRight.direction = new BABYLON.Vector3(-0.091, -0.263, 0.4);
      spotlightLeft.position = new BABYLON.Vector3(
        spotlightLeft.position.x - 0.3,
        spotlightLeft.position.y,
        spotlightLeft.position.z
      );
      spotlightRight.position = new BABYLON.Vector3(
        spotlightRight.position.x + 0.3,
        spotlightRight.position.y,
        spotlightRight.position.z
      );
      spotlightLeft.intensity = 600;
      spotlightRight.intensity = 600;
      spotlightMiddle.intensity = 0;
      spotlightLeft.diffuse = new BABYLON.Color3(1, 1, 0.95);
      spotlightRight.diffuse = new BABYLON.Color3(1, 1, 0.95);
      sun.intensity = 0.5;

      var camera = scene.cameras[1];
      camera.wheelPrecision = 80;
      camera.minZ = 0.1;
      camera.fovMode = Camera.FOVMODE_VERTICAL_FIXED;
      camera.upperBetaLimit = 1.512;
      camera.lowerBetaLimit = 0.7;
      //camera.upperAlphaLimit = 5.68
      //camera.lowerAlphaLimit = 3.650
      camera.lowerRadiusLimit = 1;
      camera.upperRadiusLimit = 4;
      camera.ellipsoid = new Vector3(1, 1, 1);
      camera.checkCollisions = true;
      scene.switchActiveCamera(camera);
      camera.attachControl(scene.getEngine().getRenderingCanvas(), true);

      var pipeline = new DefaultRenderingPipeline(
        "defaultPipeline", // The name of the pipeline
        true, // Do you want the pipeline to use HDR texture?
        scene, // The scene instance
        [scene.cameras[1], scene.getCameraByID("screenshotcam")] // The list of cameras to be attached to
      );
      pipeline.glowLayerEnabled = false;

      //pipeline.glowLayer.intensity = 0.5;
      //pipeline.glowLayer.blurKernelSize = 50;
      pipeline.imageProcessing.contrast = 1.2;
      pipeline.imageProcessing.exposure = 0.9;

      pipeline.imageProcessing.toneMappingEnabled = false;
      pipeline.imageProcessing.toneMappingType = 1;
      pipeline.imageProcessing.vignetteEnabled = true;
      pipeline.imageProcessing.vignetteWeight = 1.5;
      pipeline.fxaaEnabled = true;
      pipeline.sharpenEnabled = false;

      scene.getMeshByID("booleancube_left").isVisible = false;
      scene.getMeshByID("booleancube_right").isVisible = false;
      scene.getMeshByID("booleancube").isVisible = false;
      scene.getMeshByID("Punkt_left").isVisible = false;
      scene.getMeshByID("Punkt_right").isVisible = false;
      scene.getMeshByID("Punkt").isVisible = false;
      scene.getMeshByID("Rinne_left").isVisible = false;
      scene.getMeshByID("Rinne_right").isVisible = false;
      scene.getMeshByID("Rinne").isVisible = false;
      scene.getMeshByID("Schlitz_left").isVisible = false;
      scene.getMeshByID("Schlitz_right").isVisible = false;
      scene.getMeshByID("Schlitz").isVisible = false;
      scene.getMeshByID("Becken").isVisible = false;
      scene.getMeshByID("Doppelbecken").isVisible = false;
      let cubemat = scene.getMaterialByID("cubemat");
      cubemat.metallicF0Factor = glossfactor;
      cubemat.roughness = 0;
      /* var faucetSinkRight = scene.getMeshByID("f_sink").clone("right");
      var faucetWallRight = scene.getMeshByID("f_wall").clone("right"); */
      var hl = new BABYLON.HighlightLayer("hl1", scene);
      hl.blurHorizontalSize = 0.5;
      hl.blurVerticalSize = 0.5;

      hl.addMesh(
        scene.getMeshByID("ablage"),
        BABYLON.Color3.FromHexString("#ff8000")
      );
      var advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI(
        "UI",
        true,
        scene
      );
      advancedTexture.addControl(posLineCenter);
      advancedTexture.addControl(posLineLeft);
      advancedTexture.addControl(posLineRight);
      advancedTexture.addControl(posLineCentertext);
      advancedTexture.addControl(sinkWidthLineCenter);
      advancedTexture.addControl(sinkWidthLineLeft);
      advancedTexture.addControl(sinkWidthLineRight);
      advancedTexture.addControl(tischWidthLineCenter);
      advancedTexture.addControl(tischWidthLineLeft);
      advancedTexture.addControl(tischWidthLineRight);

      doBool(scene);
      SCENE.alwaysUpdate = true;
      //Show inspector.
      /* scene.debugLayer.show({
      embedMode: false,
    });  */
    }

    const onSceneReady = (scene) => {
      console.log(scene);
      // replace the default loading screen
      SceneLoader.Append(
        "/scenes/",
        "background_scene_babylon.babylon",
        scene,
        (scene) => setupScene(scene),
        (ev, scene) => sceneLoadProgress(ev, scene)
      );
      // This creates and positions a free camera (non-mesh)
      //var camera = new FreeCamera("cameramain", new Vector3(0, 5, -10), scene);

      // This targets the camera to scene origin
      //camera.setTarget(Vector3.Zero());

      const canvas = scene.getEngine().getRenderingCanvas();

      // This attaches the camera to the canvas
      //camera.attachControl(canvas, true);
      var camera = new ArcRotateCamera(
        "Camera",
        Math.PI / 2,
        Math.PI / 2,
        2,
        new Vector3(0, 0, -5),
        scene
      );
      camera.attachControl(canvas, true);
    };

    /**
     * Updates the scene to reflect the current configuration.
     * Will only execute if SCENE.update = true
     */
    function updateScene(scene) {
      var ablage = scene.getMeshByID("ablage");
      var mirror = scene.getMeshByID("Spiegel");
      var wand = scene.getMeshByID("wand");
      var Becken = scene.getMeshByID("csg");
      var Doppelbecken = scene.getMeshByID("Doppelbecken");
      var Punkt = scene.getMeshByID("Punkt");
      var PunktLeft = scene.getMeshByID("Punkt_left");
      var PunktRight = scene.getMeshByID("Punkt_right");
      var Schlitz = scene.getMeshByID("Schlitz");
      var SchlitzLeft = scene.getMeshByID("Schlitz_left");
      var SchlitzRight = scene.getMeshByID("Schlitz_right");
      var Rinne = scene.getMeshByID("Rinne");
      var RinneLeft = scene.getMeshByID("Rinne_left");
      var RinneRight = scene.getMeshByID("Rinne_right");
      var faucetWall = scene.getMeshByID("f_wall");
      var faucetSink = scene.getMeshByID("f_sink");
      var Soap = scene.getMeshByID("Soap");
      var faucetSinkRight = scene.getMeshByID("right.f_sink");
      var faucetWallRight = scene.getMeshByID("right.f_wall");

      var booleancube = scene.getMeshByID("booleancube");
      var booleancubeRight = scene.getMeshByID("booleancube_right");
      var booleancubeLeft = scene.getMeshByID("booleancube_left");

      var spotlightLeft = scene.getLightByID("Area");
      var spotlightRight = scene.getLightByID("Area.001");
      var sun = scene.getLightByID("Sun");

      function clamp(input: number, min: number, max: number): number {
        return input < min ? min : input > max ? max : input;
      }

      function map(
        current: number,
        in_min: number,
        in_max: number,
        out_min: number,
        out_max: number
      ): number {
        const mapped: number =
          ((current - in_min) * (out_max - out_min)) / (in_max - in_min) +
          out_min;

        return clamp(mapped, out_min, out_max);
      }
      //console.log("updateScene",spotlightLeft);
      spotlightLeft.intensity = map(
        SCENE.brightness,
        0,
        100,
        SCENE.minbrightness,
        SCENE.maxbrightness
      );
      spotlightRight.intensity = map(
        SCENE.brightness,
        0,
        100,
        SCENE.minbrightness,
        SCENE.maxbrightness
      );
      sun.intensity = map(
        SCENE.sunbrightness,
        0,
        100,
        SCENE.minbrightnesssun,
        SCENE.maxbrightnesssun
      );

      //scale Sink

      Punkt.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
      Rinne.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
      Schlitz.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
      PunktLeft.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
      RinneLeft.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
      SchlitzLeft.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
      PunktRight.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
      RinneRight.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
      SchlitzRight.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
      scene.getMeshByID("Becken").scaling.x =
        SinkConfig.tableWidth * SinkConfig.scale;
      scene.getMeshByID("Becken").scaling.y =
        SinkConfig.tableHeight / SinkConfig.minTableHeight;
      scene.getMeshByID("Becken").position.y =
        0.9 -
        ((SinkConfig.tableHeight - SinkConfig.minTableHeight) *
          SinkConfig.scale) /
          2;
        
      if(SinkConfig.tableDepth == 500){
        scene.getMeshByID("Becken").scaling.z = 1.111
        scene.getMeshByID("Becken").position.z = -0.248
      }else{
        scene.getMeshByID("Becken").scaling.z = 1
        scene.getMeshByID("Becken").position.z = -0.222
      }
      
          
      booleancube.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
      booleancubeRight.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
      booleancubeLeft.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
      
      if (SinkConfig.singleSink) {
        //switch sinkMontage

        if (SinkConfig.sinkMontage) {
          faucetWall.isVisible = false;
          faucetWallRight.isVisible = false;
          faucetSink.isVisible = true;
          faucetSinkRight.isVisible = false;
        } else {
          faucetWall.isVisible = true;
          faucetSink.isVisible = false;
          faucetWallRight.isVisible = false;
          faucetSinkRight.isVisible = false;
        }
        faucetSink.position.x =
          -((SinkConfig.tableWidth / 2) * SinkConfig.scale) +
          SinkConfig.sinkPos * SinkConfig.scale;
        faucetWall.position.x =
          -((SinkConfig.tableWidth / 2) * SinkConfig.scale) +
          SinkConfig.sinkPos * SinkConfig.scale;
        booleancube.position.x =
          -((SinkConfig.tableWidth / 2) * SinkConfig.scale) +
          SinkConfig.sinkPos * SinkConfig.scale;
        Punkt.position.x =
          -((SinkConfig.tableWidth / 2) * SinkConfig.scale) +
          SinkConfig.sinkPos * SinkConfig.scale;
        Rinne.position.x =
          -((SinkConfig.tableWidth / 2) * SinkConfig.scale) +
          SinkConfig.sinkPos * SinkConfig.scale;
        Schlitz.position.x =
          -((SinkConfig.tableWidth / 2) * SinkConfig.scale) +
          SinkConfig.sinkPos * SinkConfig.scale;
        PunktRight.isVisible = false;
        PunktLeft.isVisible = false;
        SchlitzRight.isVisible = false;
        SchlitzLeft.isVisible = false;
        RinneRight.isVisible = false;
        RinneLeft.isVisible = false;
        Punkt.isVisible = true;
        Schlitz.isVisible = true;
        Rinne.isVisible = true;
      } else {
        //switch sinkMontage
        if (SinkConfig.sinkMontage) {
          faucetWall.isVisible = false;
          faucetWallRight.isVisible = false;
          faucetSink.isVisible = true;
          faucetSinkRight.isVisible = true;
        } else {
          faucetWall.isVisible = true;
          faucetSink.isVisible = false;
          faucetWallRight.isVisible = true;
          faucetSinkRight.isVisible = false;
        }
        faucetSink.position.x =
          -((SinkConfig.tableWidth / 2) * SinkConfig.scale) +
          SinkConfig.sinkPos * SinkConfig.scale;
        faucetWall.position.x =
          -((SinkConfig.tableWidth / 2) * SinkConfig.scale) +
          SinkConfig.sinkPos * SinkConfig.scale;
        faucetSinkRight.position.x =
          (SinkConfig.tableWidth / 2) * SinkConfig.scale -
          SinkConfig.sinkPos * SinkConfig.scale;
        faucetWallRight.position.x =
          (SinkConfig.tableWidth / 2) * SinkConfig.scale -
          SinkConfig.sinkPos * SinkConfig.scale;
        booleancubeLeft.position.x =
          -((SinkConfig.tableWidth / 2) * SinkConfig.scale) +
          SinkConfig.sinkPos * SinkConfig.scale;
        booleancubeRight.position.x =
          (SinkConfig.tableWidth / 2) * SinkConfig.scale -
          SinkConfig.sinkPos * SinkConfig.scale;
        Punkt.position.x =
          -((SinkConfig.tableWidth / 2) * SinkConfig.scale) +
          SinkConfig.sinkPos * SinkConfig.scale;
        Rinne.position.x =
          -((SinkConfig.tableWidth / 2) * SinkConfig.scale) +
          SinkConfig.sinkPos * SinkConfig.scale;
        Schlitz.position.x =
          -((SinkConfig.tableWidth / 2) * SinkConfig.scale) +
          SinkConfig.sinkPos * SinkConfig.scale;
        PunktRight.position.x =
          (SinkConfig.tableWidth / 2) * SinkConfig.scale -
          SinkConfig.sinkPos * SinkConfig.scale;
        RinneRight.position.x =
          (SinkConfig.tableWidth / 2) * SinkConfig.scale -
          SinkConfig.sinkPos * SinkConfig.scale;
        SchlitzRight.position.x =
          (SinkConfig.tableWidth / 2) * SinkConfig.scale -
          SinkConfig.sinkPos * SinkConfig.scale;
        PunktLeft.position.x =
          -((SinkConfig.tableWidth / 2) * SinkConfig.scale) +
          SinkConfig.sinkPos * SinkConfig.scale;
        RinneLeft.position.x =
          -((SinkConfig.tableWidth / 2) * SinkConfig.scale) +
          SinkConfig.sinkPos * SinkConfig.scale;
        SchlitzLeft.position.x =
          -((SinkConfig.tableWidth / 2) * SinkConfig.scale) +
          SinkConfig.sinkPos * SinkConfig.scale;
        PunktRight.isVisible = true;
        PunktLeft.isVisible = true;
        SchlitzRight.isVisible = true;
        SchlitzLeft.isVisible = true;
        RinneRight.isVisible = true;
        RinneLeft.isVisible = true;
        Punkt.isVisible = false;
        Schlitz.isVisible = false;
        Rinne.isVisible = false;
      }
      Soap.position.x =
        -((SinkConfig.tableWidth / 2) * SinkConfig.scale) +
        SinkConfig.sinkPos * SinkConfig.scale +
        (SinkConfig.sinkWidth / 2) * SinkConfig.scale +
        40 * SinkConfig.scale;
      ablage.scaling.x = SinkConfig.tableWidth * SinkConfig.scale;

      //scale boolean

      //Show undertable
      if (SCENE.showUnderTable) {
        ablage.material.alpha = 1;
        ablage.material.albedoTexture =
          Becken.material.subMaterials[0].albedoTexture;
        scene.effectLayers[0].isEnabled = false;
      } else {
        ablage.material.alpha = 0.3;
        scene.effectLayers[0].isEnabled = true;
        let at = new BABYLON.Texture("scenes/wand.jpg", scene);
        ablage.material.albedoTexture = at;
      }

      //switch Scenetype
      if (!SCENE.viewType) {
        mirror.isVisible = true;
        wand.scaling.y = 0.25;
      } else {
        mirror.isVisible = false;
        wand.scaling.y = 0.12;
      }
      SCENE.update = false;

      //switch Sinktype
      if (SinkConfig.sinkType === 0) {
        if (SinkConfig.singleSink) {
          Punkt.isVisible = true;
          PunktLeft.isVisible = false;
          PunktRight.isVisible = false;
          Schlitz.isVisible = false;
          SchlitzLeft.isVisible = false;
          SchlitzRight.isVisible = false;
          Rinne.isVisible = false;
          RinneLeft.isVisible = false;
          RinneRight.isVisible = false;
        } else {
          Punkt.isVisible = false;
          PunktLeft.isVisible = true;
          PunktRight.isVisible = true;
          Schlitz.isVisible = false;
          SchlitzLeft.isVisible = false;
          SchlitzRight.isVisible = false;
          Rinne.isVisible = false;
          RinneLeft.isVisible = false;
          RinneRight.isVisible = false;
        }
      } else if (SinkConfig.sinkType === 1) {
        if (SinkConfig.singleSink) {
          Punkt.isVisible = false;
          PunktLeft.isVisible = false;
          PunktRight.isVisible = false;
          Schlitz.isVisible = false;
          SchlitzLeft.isVisible = false;
          SchlitzRight.isVisible = false;
          Rinne.isVisible = true;
          RinneLeft.isVisible = false;
          RinneRight.isVisible = false;
        } else {
          Punkt.isVisible = false;
          PunktLeft.isVisible = false;
          PunktRight.isVisible = false;
          Schlitz.isVisible = false;
          SchlitzLeft.isVisible = false;
          SchlitzRight.isVisible = false;
          Rinne.isVisible = false;
          RinneLeft.isVisible = true;
          RinneRight.isVisible = true;
        }
      } else {
        if (SinkConfig.singleSink) {
          Punkt.isVisible = false;
          PunktLeft.isVisible = false;
          PunktRight.isVisible = false;
          Schlitz.isVisible = true;
          SchlitzLeft.isVisible = false;
          SchlitzRight.isVisible = false;
          Rinne.isVisible = false;
          RinneLeft.isVisible = false;
          RinneRight.isVisible = false;
        } else {
          Punkt.isVisible = false;
          PunktLeft.isVisible = false;
          PunktRight.isVisible = false;
          Schlitz.isVisible = false;
          SchlitzLeft.isVisible = true;
          SchlitzRight.isVisible = true;
          Rinne.isVisible = false;
          RinneLeft.isVisible = false;
          RinneRight.isVisible = false;
        }
      }

      //Update Material
      //
      var tex = new BABYLON.Texture(SinkConfig.materialimage, scene);
      Becken.material.subMaterials[0].albedoTexture = tex;
      Doppelbecken.material.subMaterials[0].albedoTexture = tex;
      Rinne.material.albedoTexture = tex;
      ablage.material.albedoTexture = tex;

      if (!SCENE.viewType) {
        wand.material.albedoTexture = tex;
        if (SinkConfig.materialglossy) {
          wand.material.metallicF0Factor = glossfactor;
          wand.material.roughness = 0;
        } else {
          wand.material.metallicF0Factor = 0;
          wand.material.roughness = 1;
        }
      } else {
        let t = new BABYLON.Texture("scenes/wand.jpg");
        wand.material.albedoTexture = t;
        wand.material.metallicF0Factor = 0;
        wand.material.roughness = 1;
      }
      if (SinkConfig.materialglossy) {
        Becken.material.subMaterials[0].metallicF0Factor = glossfactor;
        Becken.material.subMaterials[0].roughness = 0;
        Doppelbecken.material.subMaterials[0].metallicF0Factor = glossfactor;
        Doppelbecken.material.subMaterials[0].roughness = 0;
        Rinne.material.metallicF0Factor = glossfactor;
        Rinne.material.roughness = 0;
        ablage.material.metallicF0Factor = glossfactor;
        ablage.material.roughness = 0;
      } else {
        Becken.material.subMaterials[0].metallicF0Factor = 0;
        Becken.material.subMaterials[0].roughness = 1;
        Doppelbecken.material.subMaterials[0].metallicF0Factor = 0;
        Doppelbecken.material.subMaterials[0].roughness = 1;
        Rinne.material.metallicF0Factor = 0;
        Rinne.material.roughness = 1;
        ablage.material.metallicF0Factor = 0;
        ablage.material.roughness = 1;
      }

      calcPrice();

      doBool(scene);
      if (SCENE.showSinkPositionLines) {
        drawSinkPos(true, scene);
      } else {
        drawSinkPos(false, scene);
      }
      if (SCENE.showTableWidthLines) {
        drawTableWidth(true, scene);
      } else {
        drawTableWidth(false, scene);
      }
      if (SCENE.showTableHeightLines) {
        drawTableHeight(true, scene);
      } else {
        drawTableHeight(false, scene);
      }
      if (SCENE.showSinkWidthLines) {
        drawSinkWidth(true, scene);
      } else {
        drawSinkWidth(false, scene);
      }

      if (SCENE.makeScreenshot) {
        SCENE.makeScreenshot = false;
        BABYLON.Tools.CreateScreenshotUsingRenderTarget(
          scene.getEngine(),
          scene.getCameraByID("screenshotcam"),
          { width: 600, height: 225 },
          function (data) {
            props.setScreenshot(data);
          },
          "image/jpg"
        );
      }
    }

    function project2D(pos, scene) {
      let engine = scene.getEngine();
      let newpos = BABYLON.Vector3.Project(
        pos,
        BABYLON.Matrix.Identity(),
        scene.getTransformMatrix(),

        scene.activeCamera.viewport.toGlobal(
          engine.getRenderWidth(true),
          engine.getRenderHeight(true)
        )
      );

      return newpos;
    }

    function drawSinkPos(show, scene) {
      let tisch = scene.getMeshByID("Becken");
      var booleanCube = scene.getMeshByID("booleancube");
      if (!SinkConfig.singleSink) {
        booleanCube = scene.getMeshByID("booleancube_left");
      }
      if (show) {
        //
        posLineLeft.isVisible = true;
        posLineCenter.isVisible = true;
        posLineRight.isVisible = true;
        let offsetY = 0;
        let offsetZ = 0.05;
        // dashed line
        let posBooleanCube = new BABYLON.Vector3(
          tisch.position.x - (SinkConfig.tableWidth / 2) * SinkConfig.scale,
          offsetY +
            tisch.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          tisch.position.z - SinkConfig.beckenTiefe * SinkConfig.scale
        );
        let posTisch = new BABYLON.Vector3(
          booleanCube.position.x,
          offsetY +
            booleanCube.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          booleanCube.position.z - SinkConfig.beckenTiefe * SinkConfig.scale
        );
        let a = project2D(posBooleanCube, scene);
        let b = project2D(posTisch, scene);
        // left line
        let posTischLeft = new BABYLON.Vector3(
          tisch.position.x - (SinkConfig.tableWidth / 2) * SinkConfig.scale,
          offsetY +
            tisch.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          tisch.position.z + SinkConfig.tableDepth * SinkConfig.scale
        );
        let posTischFront = new BABYLON.Vector3(
          tisch.position.x - (SinkConfig.tableWidth / 2) * SinkConfig.scale,
          offsetY +
            tisch.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          tisch.position.z - SinkConfig.tableDepth * SinkConfig.scale - offsetZ
        );
        let at = project2D(posTischLeft, scene);
        let bt = project2D(posTischFront, scene);
        //right line
        let posBooleanCubeRight = new BABYLON.Vector3(
          booleanCube.position.x,
          offsetY +
            tisch.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          tisch.position.z + SinkConfig.tableDepth * SinkConfig.scale
        );

        let posBooleanCubeFront = new BABYLON.Vector3(
          booleanCube.position.x,
          offsetY +
            tisch.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          tisch.position.z - SinkConfig.tableDepth * SinkConfig.scale - offsetZ
        );

        let as = project2D(posBooleanCubeRight, scene);
        let bs = project2D(posBooleanCubeFront, scene);

        let lineColor = "orange";
        let lineWidth = 3;

        posLineLeft.x1 = at.x;
        posLineLeft.y1 = at.y;
        posLineLeft.x2 = bt.x;
        posLineLeft.y2 = bt.y;
        posLineLeft.lineWidth = lineWidth;
        posLineLeft.color = lineColor;

        posLineRight.x1 = as.x;
        posLineRight.y1 = as.y;
        posLineRight.x2 = bs.x;
        posLineRight.y2 = bs.y;
        posLineRight.lineWidth = lineWidth;
        posLineRight.color = lineColor;

        posLineCenter.x1 = a.x;
        posLineCenter.y1 = a.y;
        posLineCenter.x2 = b.x;
        posLineCenter.y2 = b.y;
        posLineCenter.lineWidth = lineWidth * 1.5;
        posLineCenter.dash = [15, 5, 5, 5];
        posLineCenter.color = lineColor;

        posLineCentertext.color = lineColor;
        posLineCentertext.x = a.x;
        posLineCentertext.y = a.y;
        posLineCentertext.text = "";
      } else {
        posLineLeft.isVisible = false;
        posLineCenter.isVisible = false;
        posLineRight.isVisible = false;
      }
    }

    function drawSinkWidth(show, scene) {
      let booleanCube = scene.getMeshByID("booleancube");
      if (!SinkConfig.singleSink) {
        booleanCube = scene.getMeshByID("booleancube_left");
      }
      if (show) {
        sinkWidthLineLeft.isVisible = true;
        sinkWidthLineCenter.isVisible = true;
        sinkWidthLineRight.isVisible = true;
        let offsetY = 0;
        let offsetZ = 0.05;
        // dashed line
        let posBooleanCubeFrontLeft = new BABYLON.Vector3(
          booleanCube.position.x -
            (SinkConfig.sinkWidth / 2) * SinkConfig.scale,
          offsetY +
            booleanCube.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          booleanCube.position.z - SinkConfig.beckenTiefe * SinkConfig.scale
        );
        let posBooleanCubeFrontRight = new BABYLON.Vector3(
          booleanCube.position.x +
            (SinkConfig.sinkWidth / 2) * SinkConfig.scale,
          offsetY +
            booleanCube.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          booleanCube.position.z - SinkConfig.beckenTiefe * SinkConfig.scale
        );
        let a = project2D(posBooleanCubeFrontLeft, scene);
        let b = project2D(posBooleanCubeFrontRight, scene);

        // left line
        let posTischLeft = new BABYLON.Vector3(
          booleanCube.position.x -
            (SinkConfig.sinkWidth / 2) * SinkConfig.scale,
          offsetY +
            booleanCube.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          booleanCube.position.z + SinkConfig.beckenTiefe * SinkConfig.scale
        );
        let posTischFront = new BABYLON.Vector3(
          booleanCube.position.x -
            (SinkConfig.sinkWidth / 2) * SinkConfig.scale,
          offsetY +
            booleanCube.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          booleanCube.position.z -
            SinkConfig.beckenTiefe * SinkConfig.scale -
            offsetZ
        );
        let at = project2D(posTischLeft, scene);
        let bt = project2D(posTischFront, scene);

        //right line
        let posBooleanCubeRight = new BABYLON.Vector3(
          booleanCube.position.x +
            (SinkConfig.sinkWidth / 2) * SinkConfig.scale,
          offsetY +
            booleanCube.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          booleanCube.position.z + SinkConfig.beckenTiefe * SinkConfig.scale
        );
        let posBooleanCubeFront = new BABYLON.Vector3(
          booleanCube.position.x +
            (SinkConfig.sinkWidth / 2) * SinkConfig.scale,
          offsetY +
            booleanCube.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          booleanCube.position.z -
            SinkConfig.beckenTiefe * SinkConfig.scale -
            offsetZ
        );

        let as = project2D(posBooleanCubeRight, scene);
        let bs = project2D(posBooleanCubeFront, scene);

        let lineColor = "orange";
        let lineWidth = 3;

        sinkWidthLineLeft.x1 = at.x;
        sinkWidthLineLeft.y1 = at.y;
        sinkWidthLineLeft.x2 = bt.x;
        sinkWidthLineLeft.y2 = bt.y;
        sinkWidthLineLeft.lineWidth = lineWidth;
        sinkWidthLineLeft.color = lineColor;

        sinkWidthLineRight.x1 = as.x;
        sinkWidthLineRight.y1 = as.y;
        sinkWidthLineRight.x2 = bs.x;
        sinkWidthLineRight.y2 = bs.y;
        sinkWidthLineRight.lineWidth = lineWidth;
        sinkWidthLineRight.color = lineColor;

        sinkWidthLineCenter.x1 = a.x;
        sinkWidthLineCenter.y1 = a.y;
        sinkWidthLineCenter.x2 = b.x;
        sinkWidthLineCenter.y2 = b.y;
        sinkWidthLineCenter.lineWidth = lineWidth * 1.5;
        sinkWidthLineCenter.dash = [15, 5, 5, 5];
        sinkWidthLineCenter.color = lineColor;

        posLineCentertext.color = lineColor;
        posLineCentertext.x = a.x;
        posLineCentertext.y = a.y;
        posLineCentertext.text = "";
      } else {
        sinkWidthLineLeft.isVisible = false;
        sinkWidthLineCenter.isVisible = false;
        sinkWidthLineRight.isVisible = false;
      }
    }

    function drawTableWidth(show, scene) {
      let tisch = scene.getMeshByID("Becken");

      if (show) {
        tischWidthLineLeft.isVisible = true;
        tischWidthLineCenter.isVisible = true;
        tischWidthLineRight.isVisible = true;
        let offsetY = 0;
        let offsetZ = 0.05;
        // dashed line
        let posBooleanCubeFrontLeft = new BABYLON.Vector3(
          tisch.position.x - (SinkConfig.tableWidth / 2) * SinkConfig.scale,
          offsetY +
            tisch.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          tisch.position.z - SinkConfig.tableDepth * SinkConfig.scale
        );
        let posBooleanCubeFrontRight = new BABYLON.Vector3(
          tisch.position.x + (SinkConfig.tableWidth / 2) * SinkConfig.scale,
          offsetY +
            tisch.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          tisch.position.z - SinkConfig.tableDepth * SinkConfig.scale
        );
        let a = project2D(posBooleanCubeFrontLeft, scene);
        let b = project2D(posBooleanCubeFrontRight, scene);

        // left line
        let posTischLeft = new BABYLON.Vector3(
          tisch.position.x - (SinkConfig.tableWidth / 2) * SinkConfig.scale,
          offsetY +
            tisch.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          tisch.position.z + SinkConfig.tableDepth * SinkConfig.scale
        );
        let posTischFront = new BABYLON.Vector3(
          tisch.position.x - (SinkConfig.tableWidth / 2) * SinkConfig.scale,
          offsetY +
            tisch.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          tisch.position.z - SinkConfig.tableDepth * SinkConfig.scale - offsetZ
        );
        let at = project2D(posTischLeft, scene);
        let bt = project2D(posTischFront, scene);

        //right line
        let posBooleanCubeRight = new BABYLON.Vector3(
          tisch.position.x + (SinkConfig.tableWidth / 2) * SinkConfig.scale,
          offsetY +
            tisch.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          tisch.position.z + SinkConfig.tableDepth * SinkConfig.scale
        );
        let posBooleanCubeFront = new BABYLON.Vector3(
          tisch.position.x + (SinkConfig.tableWidth / 2) * SinkConfig.scale,
          offsetY +
            tisch.position.y +
            SinkConfig.beckenHoehe * SinkConfig.scale,
          tisch.position.z - SinkConfig.tableDepth * SinkConfig.scale - offsetZ
        );

        let as = project2D(posBooleanCubeRight, scene);
        let bs = project2D(posBooleanCubeFront, scene);

        let lineColor = "orange";
        let lineWidth = 3;

        tischWidthLineLeft.x1 = at.x;
        tischWidthLineLeft.y1 = at.y;
        tischWidthLineLeft.x2 = bt.x;
        tischWidthLineLeft.y2 = bt.y;
        tischWidthLineLeft.lineWidth = lineWidth;
        tischWidthLineLeft.color = lineColor;

        tischWidthLineRight.x1 = as.x;
        tischWidthLineRight.y1 = as.y;
        tischWidthLineRight.x2 = bs.x;
        tischWidthLineRight.y2 = bs.y;
        tischWidthLineRight.lineWidth = lineWidth;
        tischWidthLineRight.color = lineColor;

        //
        tischWidthLineCenter.x1 = a.x;
        tischWidthLineCenter.y1 = a.y;
        tischWidthLineCenter.x2 = b.x;
        tischWidthLineCenter.y2 = b.y;
        tischWidthLineCenter.lineWidth = lineWidth * 1.5;
        tischWidthLineCenter.dash = [15, 5, 5, 5];
        tischWidthLineCenter.color = lineColor;

        posLineCentertext.color = lineColor;
        posLineCentertext.x = a.x;
        posLineCentertext.y = a.y;
        posLineCentertext.text = "";
      } else {
        tischWidthLineLeft.isVisible = false;
        tischWidthLineCenter.isVisible = false;
        tischWidthLineRight.isVisible = false;
      }
    }
    function drawTableHeight(show, scene) {
      let tisch = scene.getMeshByID("Becken");

      if (show) {
      }
    }

    /**
     * doBool(scene)
     * Does the boolean calculations on the sinktable
     */
    function doBool(scene) {
      var bBecken = scene.getMeshByID("Becken");
      //var bDoppelbecken = scene.getMeshByID("Doppelbecken");
      var bbooleancubeLeft = scene.getMeshByID("booleancube_left");
      var bbooleancubeRight = scene.getMeshByID("booleancube_right");
      var bbooleancube = scene.getMeshByID("booleancube");
      if (scene.getMeshByID("csg")) {
        scene.getMeshByID("csg").dispose();
      }
      if (scene.getMeshByID("csgb")) {
        scene.getMeshByID("csgb").dispose();
      }

      if (SinkConfig.singleSink) {
        let mat = bBecken.material;
        var csga = BABYLON.CSG.FromMesh(bBecken);
        var csgb = BABYLON.CSG.FromMesh(bbooleancube);
        var subcsg = csga.subtract(csgb);
        newSink = subcsg.toMesh("csg", mat, scene);
        newSink.receiveShadows = true;
        newSink.castShadows = true;
        newSink.setEnabled(true);
        bBecken.isVisible = false;
      } else {
        let mat = bBecken.material;
        csga = BABYLON.CSG.FromMesh(bBecken);
        csgb = BABYLON.CSG.FromMesh(bbooleancubeLeft);
        var csgc = BABYLON.CSG.FromMesh(bbooleancubeRight);
        subcsg = csga.subtract(csgb);
        var subcsgb = subcsg.subtract(csgc);
        newSink = subcsgb.toMesh("csg", mat, scene);
        newSink.receiveShadows = true;
        newSink.castShadows = true;
        newSink.setEnabled(true);
        bBecken.isVisible = false;
      }
    }

    /**
     * Will run on every frame render.
     */
    const onRender = (scene) => {
      if (SCENE.alwaysUpdate) {
        updateScene(scene);
      }
    };

    return (
      <>
        <SceneComponent
          antialias
          onSceneReady={onSceneReady}
          onRender={onRender}
          adaptToDeviceRatio
          id="3D-canvas"
        />
      </>
    );
  }
  if (loading) {
    return <div>Lade Konfigurator</div>;
  }

  if (error) {
    return <div>Fehler beim Laden der Konfiguration</div>;
  }
};
export default Canvas;
