import Cookies from "js-cookie"
import Config from "../Config"
import { useNavigate } from "react-router-dom";
import React from "react";
export default function Home() {
const navigate = useNavigate();
//TODO check if not logged in customer is allowed to see the configurator. If not redirect to login
React.useEffect(()=>{
  if(Cookies.get(Config.TokenName)){
    navigate("/dashboard")
    }else{
    navigate("/configurator")
    }
})
return <div></div>
}
